import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout.js"
import SEO from "../components/seo"
const PageTemplate = ({ data, pageContext: {bg} }) => {

	return (


	<Layout fullMenu background={bg}>
		 <SEO
		      title={data.wordpressPage.title}
		      description={data.wordpressPage.excerpt}
	    />
	    <article id="main">
	      <header>
	        <h2>{data.wordpressPage.title}</h2>
	      </header>
	      <section className="wrapper style5">
	        <div className="inner" dangerouslySetInnerHTML={{ __html: data.wordpressPage.content.replace('http://', 'https://') }} />
	      </section>
	    </article>
	  </Layout>


)}
export default PageTemplate
export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
    }
  }
`